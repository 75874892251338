"use client";
import { BASE_API } from "@/config/site";
import API from "@/lib/Api";
import React, { useCallback, useEffect, useState } from "react";

const useGetBanner = () => {
  const [banner, setbanner] = useState<any>(null);

  const handleGetbanner = useCallback(async () => {
    try {
      const { data } = await API.get(`${BASE_API}/api/banners?populate=*`);
      setbanner(data);
    } catch (error) {
      setbanner(null);
      // return null;
    }
  }, []);

  useEffect(() => {
    !banner && handleGetbanner();
  }, []);

  return { banner };
};

export default useGetBanner;
