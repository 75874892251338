import axios from "axios";

const API = axios.create({
  headers: { "Content-Type": "application/json" },
  timeout: 20000,
});

API.interceptors.request.use((request) => {
  return request;
});

export default API;
