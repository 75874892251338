"use client";
import Link from "next/link";
import React, { Dispatch, SetStateAction, useState } from "react";
import { motion } from "framer-motion";
import { useAppContext } from "@/context/app-context";

interface MobileMenuProps {
  setOpenModal: Dispatch<SetStateAction<boolean>>;
}

const MobileMenu = ({ setOpenModal }: MobileMenuProps) => {
  const { dataTags } = useAppContext();
  const [shown, setShown] = useState(false);
  const [shown1, setShown1] = useState(false);
  const [shown2, setShown2] = useState(false);

  const showMenu = {
    enter: {
      opacity: 1,
      y: 0,
      display: "block",
    },
    exit: {
      y: -5,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
      transitionEnd: {
        display: "none",
      },
    },
  };

  return (
    <div className="col-12">
      <div className="mobile_menu d-block d-md-none">
        <motion.div className="slicknav_menu">
          <div
            // href="#"
            className={`slicknav_btn ${
              shown ? "slicknav_open" : "slicknav_collapsed"
            } `}
            style={{ outline: "none" }}
            onClick={() => {
              setShown(!shown);
            }}
          >
            <span className="slicknav_menutxt">MENU</span>
            <span className="slicknav_icon">
              <span className="slicknav_icon-bar"></span>
              <span className="slicknav_icon-bar"></span>
              <span className="slicknav_icon-bar"></span>
            </span>
          </div>
          <motion.ul
            variants={showMenu}
            initial="exit"
            animate={shown ? "enter" : "exit"}
            className={`slicknav_nav ${shown ? "active" : ""} `}
          >
            <li>
              <Link href="/" role="menuitem" tabIndex={0}>
                Trang chủ
              </Link>
            </li>

            {/* <li>
              <Link href="/" role="menuitem" tabIndex={0}>
                Category
              </Link>
            </li> */}
            <li
              onClick={() => {
                setShown(false);
              }}
            >
              <Link href="/news" role="menuitem" tabIndex={0}>
                Tin mới nhất
              </Link>
            </li>
            <li className="slicknav_parent slicknav_open">
              <Link
                href={
                  dataTags && dataTags?.data
                    ? `/tags/?tags=${dataTags.data[0].attributes.title}`
                    : "/"
                }
                className="slicknav_item slicknav_row"
                style={{ outline: "none" }}
              >
                <div tabIndex={0}>Danh mục</div>
                <span
                  className="slicknav_arrow"
                  onClick={() => setShown1(!shown1)}
                >
                  {shown1 ? "-" : "+"}
                </span>
              </Link>
              <motion.ul
                variants={showMenu}
                initial="exit"
                animate={shown1 ? "enter" : "exit"}
              >
                {dataTags &&
                  dataTags?.data.map((e: any, i: number) => (
                    <li key={i}>
                      <Link href={`/tags/?tags=${e.attributes.title}`}>
                        {e.attributes.title}
                      </Link>
                    </li>
                  ))}
              </motion.ul>
            </li>
            <li className="slicknav_parent slicknav_open">
              <Link
                href="/"
                className="slicknav_item slicknav_row"
                style={{ outline: "none" }}
              >
                <div tabIndex={0}>Về chúng tôi</div>
                <span
                  className="slicknav_arrow"
                  onClick={() => setShown2(!shown2)}
                >
                  {shown2 ? "-" : "+"}
                </span>
              </Link>
              <motion.ul
                variants={showMenu}
                initial="exit"
                animate={shown2 ? "enter" : "exit"}
              >
                <li>
                  <Link
                    href="/business-information"
                    onClick={() => {
                      setShown2(false);
                      setShown(false);
                    }}
                  >
                    Thông tin doanh nghiệp
                  </Link>
                </li>
                <li>
                  <Link
                    href="/business-introduction"
                    onClick={() => {
                      setShown2(false);
                      setShown(false);
                    }}
                  >
                    Giới thiệu doanh nghiệp
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      setShown2(false);
                      setShown(false);
                    }}
                    href="/products-and-services"
                  >
                    Sản phẩm dịch vụ
                  </Link>
                </li>
              </motion.ul>
            </li>
            <li
              onClick={() => {
                setShown(false);
                setOpenModal(true);
              }}
            >
              <i className="fa fa-search flex justify-end pl-[22px]"></i>
            </li>
          </motion.ul>
        </motion.div>
      </div>
    </div>
  );
};

export default MobileMenu;
