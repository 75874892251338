"use client";

import React, { useEffect } from "react";
import {
  CommandDialog,
  CommandEmpty,
  CommandInput,
  CommandList,
} from "../command/command";
import { cn } from "@/lib/utils";
import { Skeleton } from "../skeleton/skeleton";
import { useDebouncedCallback } from "use-debounce";
import API from "@/lib/Api";
import { BASE_API } from "@/config/site";
import Image from "next/image";
import Link from "next/link";

interface ComboboxProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Combobox = ({ open, setOpen }: ComboboxProps) => {
  const [query, setQuery] = React.useState("");
  const [data, setData] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);

  // Inside the Search Component...
  const handleSearch = useDebouncedCallback(async (term) => {
    try {
      setLoading(true);
      const { data } = await API.get(
        `${BASE_API}/api/posts/?filters[title][$containsi]=${term}&populate=*`
      );
      if (data) {
        setData(data);
      }
    } catch (error) {
      setData(null);
    } finally {
      setLoading(false);
    }
  }, 500);

  useEffect(() => {
    query && handleSearch(query);
  }, [query]);

  return (
    <CommandDialog
      open={open}
      onOpenChange={(open) => {
        setOpen(open);
        if (!open) {
          setQuery("");
        }
      }}
    >
      <div className=""></div>
      <CommandInput
        placeholder="Search news..."
        value={query}
        onValueChange={setQuery}
      />
      <div className="w-full h-[1px] bg-[#a8a8a8]"></div>
      <CommandList>
        {(!data || data?.data?.length === 0) && (
          <CommandEmpty
            className={cn(loading ? "hidden" : "py-6 text-center text-sm")}
          >
            No content found.
          </CommandEmpty>
        )}
        {loading ? (
          <div className="space-y-1 overflow-hidden px-1 py-2">
            <Skeleton className="h-4 w-10 rounded" />
            <Skeleton className="h-8 rounded-sm" />
            <Skeleton className="h-8 rounded-sm" />
          </div>
        ) : (
          <>
            {data &&
              data.data.map((e: any, i: number) => (
                <div
                  className="flex mx-2 my-2 py-2 border-[#a8a8a8]"
                  key={i}
                  style={{ borderBottom: "1px solid #a8a8a8" }}
                >
                  <div className="weekly2-img col-3 p-0">
                    <Image
                      src={
                        BASE_API + e.attributes.thumbnail.data.attributes.url
                      }
                      alt={e.attributes.title}
                      className="mx-auto"
                      width={100}
                      height={100}
                    />
                  </div>
                  <div className="weekly2-caption col-9">
                    <h5>
                      <Link
                        href={`/news/${e.attributes.slug}`}
                        onClick={() => {
                          setOpen(false);
                        }}
                      >
                        {e.attributes.title}
                      </Link>
                    </h5>
                  </div>
                </div>
              ))}
          </>
        )}
      </CommandList>
    </CommandDialog>
  );
};

export default Combobox;
