"use client";
import { BASE_API } from "@/config/site";
import API from "@/lib/Api";
import React, { useEffect, useState } from "react";

const useGetCategory = () => {
  const [dataTags, setDataTags] = useState<any>(null);

  const handleGetDataTags = async () => {
    try {
      const { data } = await API.get(`${BASE_API}/api/tags/`);
      setDataTags(data);
    } catch (error) {
      setDataTags(null);
      // return null;
    }
  };

  useEffect(() => {
    !dataTags && handleGetDataTags();
  }, []);

  return { dataTags };
};

export default useGetCategory;
