import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/react-loading-skeleton/dist/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/node_modules/sonner/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/app/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/react-loading-skeleton/dist/skeleton.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/layouts/site-footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/layouts/site-header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AppProvider"] */ "/app/src/context/app-context.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/styles/css/bootstrap.css");
;
import(/* webpackMode: "eager" */ "/app/src/styles/css/fontawesome-all.min.css");
;
import(/* webpackMode: "eager" */ "/app/src/styles/css/flaticon.css");
;
import(/* webpackMode: "eager" */ "/app/src/styles/css/slicknav.css");
;
import(/* webpackMode: "eager" */ "/app/src/styles/css/style.css");
