"use client";
import Image from "next/image";
import Link from "next/link";
import React from "react";
import "@/styles/css/footer.css";
import IconZalo from "../ui/icon/IconZalo";
import IconFb from "../ui/icon/IconFb";
import { useAppContext } from "@/context/app-context";
import { BASE_API } from "@/config/site";

const SiteFooter = () => {
  const subject = encodeURIComponent("Chủ đề email");
  const body = encodeURIComponent("Nội dung email");

  const mailtoLink = `mailto:marynguyen@carboncreditinstitute.vn?subject=${subject}&body=${body}`;
  const { banner } = useAppContext();


  return (
    <>
      <footer>
        {/* <!-- Footer Start--> */}
        <div className="footer-main footer-bg">
          <div className="footer-area footer-padding">
            <div className="container">
              <div className="row d-flex justify-content-between">
                <div className="col-xl-2 col-lg-2 col-md-3 col-sm-8">
                  <div className="single-footer-caption mb-50">
                    <div className="single-footer-caption mb-30">
                      {/* <!-- logo --> */}
                      <div className="footer-logo mx-auto">
                          <Image
                            src="/img/logo/logo2_footer.png"
                            alt="Company Logo"
                            className="mx-auto"
                            // objectFit="cover"
                            width={150}
                            height={150}
                            // sizes="100vw"
                            // style={{ width: "100%", height: "auto" }}
                          />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-7 col-sm-7">
                  <div className="single-footer-caption mb-50">
                    <div className="footer-tittle">
                      <h4>Thông tin liên hệ</h4>
                    </div>
                    <div className="footer-tittle">
                      <div className="footer-pera">
                        <div className="">
                          <Link
                            href="https://maps.app.goo.gl/oqAggrWGSPimx8waA"
                            passHref
                            legacyBehavior
                          >
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              className="info1"
                            >
                              <i className="fa fa-location-arrow pr-1 info1"></i>
                              90/2 Yên Thế, Phường 2, Quận Tân Bình, TP. Hồ Chí
                              Minh
                            </a>
                          </Link>
                        </div>
                        <div className="">
                          <Link href={mailtoLink} passHref legacyBehavior>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              className="info1"
                            >
                              <i className="fa fa-envelope pr-1 info1"></i>
                              marynguyen@carboncreditinstitute.vn
                            </a>
                          </Link>
                        </div>
                        <div className="">
                          <Link href="tel:+84982788108" passHref legacyBehavior>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              className="info1"
                            >
                              <i className="fa fa-phone pr-1 info1"></i>
                              +84 982 788 108
                            </a>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-5 col-sm-7">
                  <div className="single-footer-caption mb-50">
                    <div className="banner">
                      {banner && banner?.data.length >= 3 && (
                        <Link
                          href={banner?.data[2].attributes.link}
                          passHref
                          legacyBehavior
                        >
                          <a target="_blank" rel="noopener noreferrer">
                            <Image
                              src={
                                BASE_API +
                                banner?.data[2].attributes.image.data.attributes
                                  .url
                              }
                              alt="header-banner"
                              className=""
                              width={100}
                              height={100}
                              sizes="100vw"
                              style={{
                                width: "100%",
                                height: "auto",
                                objectFit: "cover",
                              }}
                            />
                          </a>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Footer End--> */}
        <div className="hotline">
          <ul className="hotline-list list flex mb-0">
            <li className="hotline-zalo">
              <Link href="https://zalo.me/0982788108" passHref legacyBehavior>
                <a target="_blank" rel="noopener noreferrer">
                  <IconZalo />
                </a>
              </Link>
            </li>
            <li className="hotline-phone">
              <Link href="tel:+84982788108" passHref legacyBehavior>
                <a target="_blank" rel="noopener noreferrer">
                  <div className="pulse">
                    <i className="fa fa-phone"></i>
                  </div>
                </a>
              </Link>
            </li>
            <li className="hotline-messenger">
              <Link
                href="https://www.facebook.com/people/Vi%E1%BB%87n-T%C3%ADn-ch%E1%BB%89-Carbon/61565176654318/?gidzl=7beSD9jFv5OLHnqrcrJiLX117362LU9SM10JRjq3uWa0HX9isL3i05975MoDK-WF010KFZ6y9iXKa4ZWLW"
                passHref
                legacyBehavior
              >
                <a target="_blank" rel="noopener noreferrer">
                  <IconFb />
                </a>
              </Link>
            </li>
          </ul>
        </div>
      </footer>
    </>
  );
};

export default SiteFooter;
