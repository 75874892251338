"use client";
import Link from "next/link";
import MobileMenu from "./mobile-menu";
import Image from "next/image";
import Combobox from "../ui/combobox/combobox";
import { useAppContext } from "@/context/app-context";
import { useState } from "react";
import { BASE_API } from "@/config/site";
import { CommandInput } from "../ui/command/command";

interface PropsSiteHeader {
  // dataTags: any;
}

const SiteHeader = () => {
  const { dataTags, banner } = useAppContext();
  const [open, setOpen] = useState(false);

  return (
    <header>
      <div className="header-area">
        <div className="main-header ">
          <div className="header-mid gray-bg">
            <div className="container">
              <div className="row d-flex align-items-center">
                <div className="col-xl-3 col-lg-3 col-md-3 d-none d-md-block">
                  <div className="logo w-[100px]">
                    <Link href="/">
                      <Image
                        src="/img/logo/logo.png"
                        alt="Company Logo"
                        className=""
                        width={100}
                        height={100}
                        sizes="100vw"
                        style={{
                          width: "100%",
                          height: "auto",
                          objectFit: "cover",
                        }}
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-xl-9 col-lg-9 col-md-9">
                  <div className="header-banner f-right ">
                    {banner && banner?.data.length >= 1 && (
                      <Link
                        href={banner?.data[0].attributes.link}
                        passHref
                        legacyBehavior
                      >
                        <a target="_blank" rel="noopener noreferrer">
                          <Image
                            src={
                              BASE_API +
                              banner?.data[0].attributes.image.data.attributes
                                .url
                            }
                            alt="header-banner"
                            className=""
                            width={100}
                            height={100}
                            sizes="100vw"
                            style={{
                              width: "100%",
                              height: "auto",
                              objectFit: "cover",
                            }}
                          />
                        </a>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="header-bottom header-sticky">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-xl-9 col-lg-9 col-md-10 header-flex">
                  {/* <!-- sticky --> */}
                  <div className="sticky-logo info-open">
                    <Link href="/">
                      <Image
                        src="/img/logo/logo.png"
                        alt="Company Logo"
                        className=""
                        width={70}
                        height={45}
                      />
                    </Link>
                  </div>
                  {/* <!-- Main-menu --> */}
                  <div className="main-menu d-none d-md-block">
                    <nav>
                      <ul id="navigation">
                        <li>
                          <Link href="/">Trang chủ</Link>
                        </li>
                        <li>
                          <Link href="/">Danh mục</Link>
                          <ul className="submenu">
                            {dataTags &&
                              dataTags?.data.map((e: any, i: number) => (
                                <li key={i}>
                                  <Link
                                    href={`/tags/?tags=${e.attributes.title}`}
                                  >
                                    {e.attributes.title}
                                  </Link>
                                </li>
                              ))}
                          </ul>
                        </li>
                        <li>
                          <Link href="/news">Tin mới nhất</Link>
                        </li>
                        <li>
                          <Link href="/business-information">Về chúng tôi</Link>
                          <ul className="submenu">
                            <li>
                              <Link href="/business-information">
                                Thông tin doanh nghiệp
                              </Link>
                            </li>
                            <li>
                              <Link href="/business-introduction">
                                Giới thiệu doanh nghiệp
                              </Link>
                            </li>
                            <li>
                              <Link href="/products-and-services">
                                Sản phẩm dịch vụ
                              </Link>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-2 lg-px-0">
                  <div className="header-right f-right w-[100%] flex justify-start">
                    <div
                      className="nav-search search-switch d-none d-md-block d-lg-none"
                      onClick={() => setOpen(true)}
                    >
                      <i className="fa fa-search"></i>
                    </div>
                    <div className="d-none d-lg-block px-2 nav-search-1">
                      <div
                        className=" w-full h-[40px] border-[1px] border-[#fff] text-[#bebebe] p-2 py-1 rounded-md text-[14px] pr-4 flex items-center cursor-pointer"
                        onClick={() => setOpen(true)}
                      >
                        <div className="text-[#bebebe]">
                          <i className="fa fa-search text-white pr-2"></i> Tìm
                          kiếm bài viết...
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- Mobile Menu --> */}
                <MobileMenu setOpenModal={setOpen} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Combobox setOpen={setOpen} open={open} />
    </header>
  );
};

export default SiteHeader;
