export type SiteConfig = typeof siteConfig;
export const BASE_API = "https://api.vientinchicarbon.com";
export const URL = "https://vientinchicarbon.com";

// const links = {
//   x: "https://twitter.com/sadmann17",
//   github: "https://github.com/sadmann7/skateshop",
//   githubAccount: "https://github.com/sadmann7",
//   discord: "https://discord.com/users/sadmann7",
//   calDotCom: "https://cal.com/sadmann7",
// };

export const siteConfig = {
  name: "Carbon News Page",
  description: "Nơi cập nhật những tin tức nóng hổi về Lĩnh vực Tín chỉ Carbon",
  url: "https://skateshop.sadmn.com",
  ogImage: "https://skateshop.sadmn.com/opengraph-image.png",
};
