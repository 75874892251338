"use client";

import React, { useState, useContext, createContext } from "react";
import useGetCategory from "@/hook/useGetCategory";
import useGetBanner from "@/hook/useGetBanner";

const MyContext = createContext<{ dataTags: any; banner: any }>({
  dataTags: null,
  banner: null,
});

export const AppProvider = ({ children }: any) => {
  const { dataTags } = useGetCategory();
  const { banner } = useGetBanner();
  return (
    <MyContext.Provider value={{ dataTags, banner }}>
      {children}
    </MyContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(MyContext);
};
